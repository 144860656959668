<template>
  <div class="switch-container">
    <label v-if="$slots['default']" class="switch-label material-label">
      <slot />
    </label>
    <el-switch
      ref="switch"
      v-model="currentValue"
      style="display: inline-block"
      :name="name"
      :readonly="readonly"
      :required="required"
      :disabled="disabled"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      :active-text="$t(activeText)"
      :inactive-text="$t(inactiveText)"
      @input="handleModelInput"
    >
    </el-switch>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    activeColor: {
      type: String,
      default: '#6bb80a'
    },
    inactiveColor: {
      type: String,
      default: '#c7c7c7'
    },
    activeText: {
      type: String,
      default: 'common.switchOn'
    },
    inactiveText: {
      type: String,
      default: 'common.switchOff'
    }
  },
  data() {
    return {
      currentValue: this.value
    };
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    focus() {
      this.$refs.switch.focus();
    },
    handleModelInput(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>
<style lang="scss" scoped>
.switch-container {
  margin-bottom: 5px;
  .switch-label {
    margin-right: 10px;
  }
  .material-label {
    color: #606266;
  }
}
</style>
<style lang="scss">
@import '@/styles/variables.scss';
.switch-container {
  input:focus ~ .el-switch__core {
    border-color: $-colorPrimary !important;
    border-style: dashed;
  }
}
</style>
