<template>
  <div class="suggestion-item" :class="wrapperClasses">
    <p v-for="(contentLine, idx) in contentLines" :key="idx" v-html="contentLine"></p>
  </div>
</template>

<script>
import { boldAndEscapeHTMLString } from '@/utils';

export default {
  props: {
    highlight: [String, Number],
    content: [Array, String, Number]
  },
  computed: {
    contentLines() {
      const contentAsArray = Array.isArray(this.content) ? this.content : [this.content];
      return contentAsArray.map(content =>
        this.highlight ? boldAndEscapeHTMLString(content + '', this.highlight + '') : content
      );
    },
    wrapperClasses() {
      return {
        'multiple-lines': this.contentLines.length > 1
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.suggestion-item {
  &.multiple-lines {
    font-size: 0.9em;
    padding-bottom: 0.8em;
    line-height: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
  }
}
</style>
